<template>
  <div>
    <b-col class="content-header-left mb-2" cols="12" md="9">
      <b-row class="breadcrumbs-top">
        <b-col cols="4" class="pl-0">
          <b-input-group
            class="input-group-merge mb-1"
            style="width: max-content;"
          >
            <b-form-input
              v-if="getProjectData"
              v-model="getProjectData.name"
              :disabled="!isEditProjectName"
              size="lg"
              class="font-weight-bold"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="editProjectIcon"
                class="cursor-pointer"
                @click="editProjectName"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </b-col>
    <b-tabs pills v-model="currentTab" @input="changeSource">
      <b-tab
        v-for="source in getSourcesList"
        :key="source.id"
        :title="source.title ? source.title : source.name"
      >
      </b-tab>
      <template #tabs-end>
        <b-nav-item
          role="presentation"
          @click.stop="test"
          href="#"
          class="add-source-button ml-1"
        >
          <feather-icon size="21" icon="PlusIcon" />Добавить сцену
        </b-nav-item>
      </template>
    </b-tabs>
    <b-row class="match-height">
      <b-col class="preview-wrapper" md="6">
        <b-card no-header class="video-body">
          <b-row class="w-100">
            <b-col md="8" sm="12">
              <div class="video-content-wrapper">
                <div @click="playPauseVideo">
                  <video-background
                    ref="videobackground"
                    :loop="false"
                    :src="currentVideo"
                    style="max-height: 650px; height: 100vh; max-width: 366px; border-radius: 15px"
                    :muted="isMuted"
                    v-if="currentVideo"
                    @ended="videoEnds"
                    @playing="videoPlaying"
                    :overlay="isVideoOverlay ? 'rgba(0, 0, 0, 0.3)' : ''"
                  >
                    <b-row class="mt-1 ">
                      <b-col
                        class="voice-controller d-flex justify-content-between px-3"
                      >
                        <feather-icon
                          @click.stop="setVoice"
                          size="21"
                          icon="Volume2Icon"
                          v-if="!isMuted"
                        />
                        <feather-icon
                          size="21"
                          icon="VolumeXIcon"
                          v-if="isMuted"
                          @click.stop="setVoice"
                        />
                        <feather-icon
                          size="21"
                          icon="RepeatIcon"
                          v-if="isVideoEnded"
                          @click.stop="videoRepeat"
                        />
                      </b-col>
                    </b-row>
                    <b-row
                      class="video-custom-button"
                      v-if="!answersSentSuccess"
                    >
                      <b-col class="controls-buttons-wrapper px-2">
                        <div
                          v-if="getCurrentSource.interactionType === 'socials'"
                          class="socials-wrapper"
                        >
                          <img
                            v-if="getSocialsList.isWhatsapp"
                            @click.stop="openSocial(getSocialsList.whatsapp)"
                            :src="
                              require('../../../assets/images/socials/whatsapp.png')
                            "
                            alt="whatsapp"
                            width="90"
                            class="mx-1"
                          />
                          <img
                            v-if="getSocialsList.isTelegram"
                            @click.stop="openSocial(getSocialsList.telegram)"
                            :src="
                              require('../../../assets/images/socials/telegram.png')
                            "
                            alt="telegram"
                            width="90"
                            class="mx-1"
                          />
                          <img
                            v-if="getSocialsList.isViber"
                            @click.stop="openSocial(getSocialsList.viber)"
                            :src="
                              require('../../../assets/images/socials/viber.png')
                            "
                            alt="viber"
                            width="90"
                            class="mx-1"
                          />
                          <img
                            v-if="getSocialsList.isInstagram"
                            @click.stop="openSocial(getSocialsList.instagram)"
                            :src="
                              require('../../../assets/images/socials/instagram.png')
                            "
                            alt="viber"
                            width="90"
                            class="mx-1"
                          />
                          <img
                            v-if="getSocialsList.isVk"
                            @click.stop="openSocial(getSocialsList.vk)"
                            :src="
                              require('../../../assets/images/socials/vk.png')
                            "
                            alt="viber"
                            width="90"
                            class="mx-1"
                          />
                        </div>
                        <div v-else-if="isForm" class="px-2">
                          <h4 style="color: white;">
                            {{
                              getCurrentForm.title
                                ? getCurrentForm.title
                                : getCurrentForm.name
                            }}
                          </h4>
                          <p class="card-text">
                            {{ getCurrentForm.description }}
                          </p>
                          <validation-observer ref="leadFormsRules">
                            <b-row
                              v-for="question in getCurrentForm.questions"
                              :key="question.id"
                              class="form-elements"
                            >
                              <b-col>
                                <validation-provider
                                  #default="{ errors }"
                                  :name="question.title"
                                  :vid="question.id"
                                  :rules="getValidationType(question.type)"
                                  mode="passive"
                                >
                                  <b-form-input
                                    :id="question.id"
                                    v-model="
                                      leadFormsAnswers.answers[question.id]
                                    "
                                    :placeholder="question.title"
                                    @click.stop
                                    :state="errors[0] ? false : null"
                                  >
                                  </b-form-input>
                                </validation-provider>
                              </b-col>
                            </b-row>
                          </validation-observer>
                          <b-row class="form-elements-button">
                            <b-col class="w-100">
                              <b-button-group class="w-100">
                                <b-button
                                  @click.stop="saveFormAnswers"
                                  class="w-100"
                                  :style="
                                    'background-color:' +
                                      sendFormColor +
                                      ' !important'
                                  "
                                  variant="primary"
                                  size="lg"
                                  squared
                                >
                                  Отправить
                                </b-button>
                                <b-button
                                  variant="flat-secondary"
                                  squared
                                  @click.stop="closeForm"
                                >
                                  <feather-icon icon="XIcon" size="21" />
                                </b-button>
                              </b-button-group>
                            </b-col>
                          </b-row>
                        </div>
                        <b-col v-else>
                          <b-button
                            class="w-100 custom-button"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            v-for="button in currentVideoControls"
                            size="lg"
                            :key="button.id"
                            :style="
                              `background-color: ${
                                button.background
                                  ? button.background.hex
                                  : '#7367f0'
                              } !important`
                            "
                            @click.stop="handleAction(button)"
                          >
                            {{ button.text }}
                          </b-button>
                        </b-col>
                      </b-col>
                    </b-row>
                    <div v-else class="answer-success-overlay">
                      <div>
                        <feather-icon size="58" icon="CheckCircleIcon" />
                        <div>
                          {{
                            getCurrentForm.sucсessText
                              ? getCurrentForm.sucсessText
                              : "Спасибо, Ваша заявка принята"
                          }}
                        </div>
                      </div>
                    </div>
                  </video-background>
                  <div
                    v-else
                    style="background-color: gray; max-height: 650px; height: 100vh; max-width: 366px; width: 100%; border-radius: 15px;"
                  ></div>
                </div>
              </div>
            </b-col>
            <b-col
              md="4"
              sm="12"
              class="preview-tabs-wrapper mt-md-0 mt-4"
              v-if="getCurrentSource"
            >
              <b-input-group class="input-group-merge mb-1">
                <b-form-input
                  v-if="getCurrentSource"
                  v-model="getCurrentSource.title"
                  :disabled="!isEditSceneName"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="editSceneIcon"
                    class="cursor-pointer"
                    @click="editSceneName"
                  />
                </b-input-group-append>
              </b-input-group>
              <label for="interaction-type">
                Выберите тип взаимодействия с виджетом:
              </label>
              <b-form-select
                id="interaction-type"
                :options="typeOptions"
                class="mb-1"
                v-model="getCurrentSource.interactionType"
                @change="changeType"
              >
              </b-form-select>
              <b-tabs
                vertical
                pills
                nav-wrapper-class="nav-vertical"
                v-model="currentSettingsTab"
                @input="changeSettings"
                v-if="currentTab !== null"
              >
                <b-tab>
                  <template #title>
                    <feather-icon icon="FilmIcon" size="18" />
                    <span> Видео </span>
                  </template>
                </b-tab>
                <b-tab
                  :disabled="!currentVideo"
                  v-if="getCurrentSource.interactionType === 'socials'"
                >
                  <template #title>
                    <feather-icon icon="MenuIcon" size="18" />
                    <span> Мессенджеры </span>
                  </template>
                </b-tab>
                <b-tab :disabled="!currentVideo" v-else>
                  <template #title>
                    <feather-icon icon="MenuIcon" size="18" />
                    <span> Элементы </span>
                  </template>
                </b-tab>
                <b-tab :disabled="!currentVideo">
                  <template #title>
                    <feather-icon icon="LinkIcon" size="18" />
                    <span> Интеграции </span>
                  </template>
                </b-tab>
                <b-tab :disabled="!currentVideo">
                  <template #title>
                    <feather-icon icon="ShareIcon" size="18" />
                    <span> Встраивание </span>
                  </template>
                </b-tab>
                <b-tab :disabled="!currentVideo">
                  <template #title>
                    <feather-icon icon="MaximizeIcon" size="18" />
                    <span> Генерация QR </span>
                  </template>
                </b-tab>
              </b-tabs>
              <hr />
              <b-button
                variant="outline-danger"
                style="border:none !important"
                @click="deleteSource"
              >
                <feather-icon icon="XIcon" />
                Удалить сцену
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col class="controls-wrapper" md="6">
        <b-card
          no-header
          footer-tag="footer"
          v-if="isControlsSettings && currentTab !== null"
        >
          <b-row class="w-100">
            <b-col md="5" sm="12">
              <div style="width: -webkit-fill-available;">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="addButton"
                  class="mb-2"
                >
                  Добавить кнопку
                </b-button>
                <draggable
                  v-model="currentVideoControls"
                  tag="ul"
                  class="list-group list-group-flush cursor-move"
                >
                  <transition-group type="transition" name="flip-list">
                    <b-list-group-item
                      v-for="item in currentVideoControls"
                      :key="item.id"
                      tag="li"
                      style="margin-bottom: 4px;"
                      :style="
                        isActiveCotrolEdit(item)
                          ? 'border-right: 4px solid #7367f0'
                          : ''
                      "
                      @click="editControl(item)"
                    >
                      <strong> {{ item.text }}</strong>
                    </b-list-group-item>
                  </transition-group>
                </draggable>
              </div>
            </b-col>
            <b-col md="7" sm="12" v-if="isEditControl">
              <b-row v-if="isEditControl">
                <validation-observer ref="controlsRules">
                  <b-col md="12" class="mb-2">
                    <label for="action-text">
                      Текст на кнопке
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="Текст"
                      vid="action-text"
                      rules="required|min:2"
                    >
                      <b-form-input
                        id="action-text"
                        v-model="controlForEdit.text"
                      >
                      </b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col md="12" class="mb-2">
                    <validation-provider
                      name="Сцена"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="action-type"
                        label="Действие при клике на кнопку:"
                        label-for="action-type"
                      >
                        <b-form-select
                          id="action-type"
                          v-model="controlForEdit.action"
                          :options="actionsOptions"
                        >
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-if="controlForEdit.action === 'nextFrame'"
                    md="12"
                    class="mb-2"
                  >
                    <validation-provider
                      name="Сцена"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="action-next-frame"
                        label="Выберите следующую сцену:"
                        label-for="exampleInput3"
                      >
                        <b-form-select
                          id="action-next-frame"
                          v-model="controlForEdit.nextFrame"
                          :options="frameOptions"
                          @change="
                            createSourceControl(controlForEdit.nextFrame)
                          "
                        >
                          <b-form-select-option value="add">
                            + Добавить сцену
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-else-if="controlForEdit.action === 'url'"
                    md="12"
                    class="mb-2"
                  >
                    <label for="action-goTo">
                      Ссылка:
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="Ссылка"
                      vid="action-goTo"
                      rules="required"
                    >
                      <b-form-input
                        id="action-goTo"
                        v-model="controlForEdit.goTo"
                      >
                      </b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-else-if="controlForEdit.action === 'leadForm'"
                    md="12"
                    class="mb-2"
                  >
                    <validation-provider
                      name="Форма"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="action-lead-form"
                        label="Выберите форму для отображения:"
                        label-for="action-lead-form"
                      >
                        <b-form-select
                          id="action-lead-form"
                          v-model="controlForEdit.leadForm"
                          :options="leadFormsOptions"
                          @change="createFormControl(controlForEdit.leadForm)"
                        >
                          <b-form-select-option value="add">
                            + Добавить форму
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col
                    v-else-if="controlForEdit.action === 'anchor'"
                    md="12"
                    class="mb-2"
                  >
                    <label for="action-anchor">
                      Введите якорь:
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      name="Якорь"
                      vid="action-achor"
                      rules="required"
                    >
                      <b-form-input
                        id="action-anchor"
                        placeholder="#example"
                        v-model="controlForEdit.anchor"
                      >
                      </b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- <b-col
                    v-else-if="controlForEdit.action === 'socials'"
                    md="12"
                    class="mb-2"
                  >
                    <small class="text-primary">
                      *Включите как минимум один контакт
                    </small>
                    <validation-observer ref="socialsRules">
                      <label for="action-whatsapp">
                        Введите ссылку на WhatsApp:
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Whatsapp"
                        :rules="getSocialsValidation('whatsapp')"
                      >
                        <span
                          class="d-flex align-items-center"
                          style="width: fit-content"
                        >
                          <b-form-input
                            id="action-whatsapp"
                            placeholder="https://wa.me/1XXXXXXXXXX"
                            v-model="controlForEdit.whatsapp"
                          >
                          </b-form-input>
                          <b-form-checkbox
                            class="ml-1"
                            v-model="controlForEdit.isWhatsapp"
                            switch
                          >
                          </b-form-checkbox>
                        </span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <label for="action-telegram" class="mt-1">
                        Введите ссылку на Telegram:
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Telegram"
                        :rules="getSocialsValidation('telegram')"
                      >
                        <span
                          class="d-flex align-items-center"
                          style="width: fit-content"
                        >
                          <b-form-input
                            id="action-telegram"
                            placeholder="https://t.me/username"
                            v-model="controlForEdit.telegram"
                          >
                          </b-form-input>
                          <b-form-checkbox
                            class="ml-1"
                            v-model="controlForEdit.isTelegram"
                            switch
                          >
                          </b-form-checkbox>
                        </span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                      <label for="action-whatsapp" class="mt-1">
                        Введите ссылку на Viber:
                      </label>
                      <validation-provider
                        #default="{ errors }"
                        name="Viber"
                        :rules="getSocialsValidation('viber')"
                      >
                        <span
                          class="d-flex align-items-center"
                          style="width: fit-content"
                        >
                          <b-form-input
                            id="action-viber"
                            placeholder="https://viber.me/username"
                            v-model="controlForEdit.viber"
                          >
                          </b-form-input>
                          <b-form-checkbox
                            class="ml-1"
                            v-model="controlForEdit.isViber"
                            switch
                          >
                          </b-form-checkbox>
                        </span>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </validation-observer>
                  </b-col> -->
                  <b-col md="12" class="mb-2">
                    <label for="action-color">
                      Выберите цвет кнопки
                    </label>
                    <twitter-picker
                      v-model="controlForEdit.background"
                      style="margin-top: 4px;"
                    >
                    </twitter-picker>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      v-b-toggle.collapse-1
                      variant="flat-primary"
                    >
                      Палитра
                    </b-button>
                    <b-collapse id="collapse-1" class="mt-2">
                      <chrome-picker
                        v-model="controlForEdit.background"
                        style="margin-top: 4px;"
                      >
                      </chrome-picker>
                    </b-collapse>
                  </b-col>
                </validation-observer>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-button
                    variant="outline-danger"
                    @click="deleteButton(controlForEdit)"
                  >
                    Удалить кнопку
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <template #footer>
            <b-button variant="success" @click="saveEditControl">
              Сохранить
            </b-button>
          </template>
        </b-card>
        <b-card
          no-header
          v-if="isSocialsSettings && currentTab !== null"
          footer-tag="footer"
        >
          <div>
            <small class="text-primary">
              *Включите как минимум один контакт (максимум 3)
            </small>
            <div>
              <validation-observer ref="socialsRules">
                <label for="action-whatsapp">
                  Введите ссылку на WhatsApp:
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Whatsapp"
                  :rules="getSocialsValidation('whatsapp')"
                >
                  <span class="d-flex align-items-center">
                    <b-form-input
                      id="action-whatsapp"
                      placeholder="https://wa.me/1XXXXXXXXXX"
                      v-model="getSocialsList.whatsapp"
                    >
                    </b-form-input>
                    <b-form-checkbox
                      class="ml-1"
                      v-model="getSocialsList.isWhatsapp"
                      switch
                      :disabled="
                        turnedSocials >= 3 && !getSocialsList.isWhatsapp
                      "
                    >
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <label for="action-telegram" class="mt-1">
                  Введите ссылку на Telegram:
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Telegram"
                  :rules="getSocialsValidation('telegram')"
                >
                  <span class="d-flex align-items-center">
                    <b-form-input
                      id="action-telegram"
                      placeholder="https://t.me/username"
                      v-model="getSocialsList.telegram"
                    >
                    </b-form-input>
                    <b-form-checkbox
                      class="ml-1"
                      v-model="getSocialsList.isTelegram"
                      switch
                      :disabled="
                        turnedSocials >= 3 && !getSocialsList.isTelegram
                      "
                    >
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <label for="action-whatsapp" class="mt-1">
                  Введите ссылку на Viber:
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Viber"
                  :rules="getSocialsValidation('viber')"
                >
                  <span class="d-flex align-items-center">
                    <b-form-input
                      id="action-viber"
                      placeholder="https://viber.me/username"
                      v-model="getSocialsList.viber"
                    >
                    </b-form-input>
                    <b-form-checkbox
                      class="ml-1"
                      v-model="getSocialsList.isViber"
                      switch
                      :disabled="turnedSocials >= 3 && !getSocialsList.isViber"
                    >
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <label for="action-whatsapp" class="mt-1">
                  Введите ссылку на Instagram (ig.me/m/user или instagram/user):
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="Instagram"
                  :rules="getSocialsValidation('instagram')"
                >
                  <span class="d-flex align-items-center">
                    <b-form-input
                      id="action-viber"
                      placeholder="https://ig.me/m/username"
                      v-model="getSocialsList.instagram"
                    >
                    </b-form-input>
                    <b-form-checkbox
                      class="ml-1"
                      v-model="getSocialsList.isInstagram"
                      switch
                      :disabled="
                        turnedSocials >= 3 && !getSocialsList.isInstagram
                      "
                    >
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <label for="action-whatsapp" class="mt-1">
                  Введите ссылку на VK:
                </label>
                <validation-provider
                  #default="{ errors }"
                  name="VK"
                  :rules="getSocialsValidation('vk')"
                >
                  <span class="d-flex align-items-center">
                    <b-form-input
                      id="action-viber"
                      placeholder="https://vk.com/username"
                      v-model="getSocialsList.vk"
                    >
                    </b-form-input>
                    <b-form-checkbox
                      class="ml-1"
                      v-model="getSocialsList.isVk"
                      switch
                      :disabled="turnedSocials >= 3 && !getSocialsList.isVk"
                    >
                    </b-form-checkbox>
                  </span>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
            </div>
          </div>
          <template #footer>
            <b-button variant="success" @click="saveSocials">
              Сохранить
            </b-button>
          </template>
        </b-card>
        <b-card no-header v-if="isVideoSettings && currentTab !== null">
          <div>
            <label for="uploader">
              Загрузить/Изменить видео (Маскимальный размер: 40Мб, длительность:
              60 сек.):</label
            >
            <b-form-file
              id="uploader"
              placeholder="Выберите файл"
              drop-placeholder="Перетащите видео сюда"
              @change="previewVideo"
              accept="video/mp4,video/x-m4v,video/*"
              v-model="fileToUpload"
              browse-text="Выбрать"
            >
            </b-form-file>
            <small class="text-danger" v-if="file && !checkFile">
              Неверный размер или длительность</small
            >
          </div>
          <div class="mt-2">
            <b-col>
              <label for="video-duration">
                Длительность видео:
              </label>
              <span id="video-duration">
                {{ getCurrentSource.duration }}
              </span>
            </b-col>
          </div>
          <div v-if="showUpload" class="mt-2">
            <b-button @click="onUpload" variant="primary" :disabled="!checkFile"
              >Загрузить</b-button
            >
          </div>
          <div v-if="showProgress" class="mt-2">
            <p>Progress</p>
            <b-progress v-model="progress" max="100" />
          </div>
          <!-- <div class="mt-2">
            <label for="time-slider"> Выберите отрезок (3-15 сек.) </label>
            <vue-slider
              id="time-slider"
              v-if="sliderShow"
              v-model="sliderValue"
              :min-range="3.0"
              :max-range="15.0"
              :min="0"
              :max="getCurrentSource.duration ? getCurrentSource.duration : 100"
              :interval="0.1"
              :lazy="true"
            />
          </div> -->
        </b-card>
        <b-card no-header v-if="isIntegrationsSettings && currentTab !== null">
          <div>
            <label for="uploader"> Выберите интеграции</label>
            <vue-good-table
              :columns="integrationColumns"
              :rows="integrationsList"
              :rtl="direction"
              :bordered="false"
              class="projects-table"
              styleClass="vgt-table"
            >
              <template slot="table-row" slot-scope="props">
                <span
                  v-if="props.column.field === 'isTurned'"
                  class="text-nowrap"
                >
                  <span class="text-nowrap">
                    <b-form-checkbox
                      name="check-button"
                      switch
                      v-model="props.row.isTurned"
                      @change="setIntegration(props.row)"
                    >
                    </b-form-checkbox>
                  </span>
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
        <b-card no-header v-if="isInsertSettings && currentTab !== null">
          <b-row>
            <b-col md="12" class="mb-2">
              <label for="script-copy">
                Скопируйте внутрь {{ "<" }}body{{ ">" }}
              </label>
              <div style="position: relative">
                <feather-icon
                  icon="ClipboardIcon"
                  style="position: absolute;top: 10px; right: 18px;"
                  size="21"
                  @click="doCopy(scriptString)"
                />
                <b-form-textarea v-model="scriptString" readonly no-resize>
                </b-form-textarea>
              </div>
            </b-col>
            <b-col md="12">
              <label for="script-copy">
                Вставьте виджет там, где вам удобно:
              </label>
              <b-tabs align="center">
                <b-tab>
                  <template #title>
                    <span>Виджетом</span>
                  </template>
                  <div style="position: relative">
                    <b-form-textarea
                      v-model="widgetInputString"
                      rows="5"
                      readonly
                      no-resize
                    >
                    </b-form-textarea>
                    <feather-icon
                      icon="ClipboardIcon"
                      style="position: absolute;top: 10px; right: 18px;"
                      size="21"
                      @click="doCopy(widgetInputString)"
                    />
                  </div>
                </b-tab>
                <b-tab>
                  <template #title>
                    <span>В тело страницы</span>
                  </template>
                  <div style="position: relative">
                    <b-form-textarea
                      v-model="bodyInputString"
                      rows="5"
                      readonly
                      no-resize
                    >
                    </b-form-textarea>
                    <feather-icon
                      icon="ClipboardIcon"
                      style="position: absolute;top: 10px; right: 18px;"
                      size="21"
                      @click="doCopy(bodyInputString)"
                    />
                  </div>
                </b-tab>
              </b-tabs>
            </b-col>
            <b-col md="12" class="align-end mt-4">
              <b-button
                variant="primary"
                class="float-right"
                @click="
                  $router.push({
                    name: 'project-preview',
                    params: { id: $route.params.id, name: getProjectData.name },
                  })
                "
              >
                Предварительный просмотр
              </b-button>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-header v-if="isQrSettings && currentTab !== null">
          <b-row>
            <b-col md="12" class="mb-2">
              <label for="qr-integration"> Прямая ссылка на Квест:</label>
              <div style="position: relative">
                <feather-icon
                  icon="ClipboardIcon"
                  style="position: absolute;top: 10px; right: 18px;"
                  size="21"
                  @click="doCopy(getDirectLink)"
                />
                <b-form-textarea v-model="getDirectLink" readonly no-resize>
                </b-form-textarea>
              </div>
            </b-col>
            <b-col md="12">
              <label for="qr-integration"> Можете поделиться QR:</label>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-img :src="getQrLink" id="qr-integration" center> </b-img>
            </b-col>
            <b-col class="mt-2" md="12">
              <label for="qr-download"> Или: </label>
              <b-button @click="downloadQr" variant="primary" class="ml-1">
                Скачать PNG
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-create"
      cancel-variant="outline-secondary"
      ok-title="Создать"
      cancel-title="Закрыть"
      centered
      title="Создать сцену"
      @ok="addSource(createSourceName)"
      @hide="clearSource()"
    >
      <b-form @submit.prevent="addSource(createSourceName)">
        <b-form-group>
          <label for="name">Название:</label>
          <b-form-input
            id="name"
            placeholder="Введите название"
            v-model="createSourceName"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-form-create"
      cancel-variant="outline-secondary"
      ok-title="Создать"
      cancel-title="Закрыть"
      centered
      title="Создать форму"
      @ok="
        createLeadForm({
          name: createFormName,
          title: createFormTitle,
          sucсessText: createFormSuccess,
          questions: questionsList,
        })
      "
      @hide="resetFormModal"
    >
      <b-form>
        <b-form-group>
          <label for="name">Название:</label>
          <b-form-input
            id="name"
            placeholder="Введите название"
            v-model="createFormName"
          />
        </b-form-group>
        <b-form-group>
          <label for="name">Заголовок Лид Формы:</label>
          <b-form-input
            id="name"
            placeholder="Введите заголовок"
            v-model="createFormTitle"
          />
        </b-form-group>
        <label> Поля: </label>
        <div
          class="d-flex"
          v-for="question in questionsList"
          :key="question.id"
        >
          <b-row class="w-2/6">
            <b-col>
              <label for="question-type"> Тип </label>
              <b-form-select
                id="question-type"
                v-model="question.type"
                :options="questionOptionsList"
                :disabled="true"
              >
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="w-2/6">
            <b-col>
              <label for="question-title"> Название поля </label>
              <b-form-input
                id="question-title"
                v-model="question.title"
                placeholder="Введите название"
              >
              </b-form-input>
            </b-col>
          </b-row>
          <b-row class="w-1/6">
            <b-col> </b-col>
          </b-row>
          <b-row class="w-1/6">
            <b-col class="w-100">
              <label for="question-enabled">Активно</label>
              <b-form-checkbox
                v-model="question.enabled"
                style="margin-top: 0.5rem; margin-left: 1rem !important; width: fit-content;"
              >
              </b-form-checkbox>
            </b-col>
          </b-row>
        </div>
        <b-form-group class="mt-2">
          <label for="name">Текст после отправки формы:</label>
          <b-form-input
            id="name"
            placeholder="Введите сообщение"
            v-model="createFormSuccess"
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BDropdown,
  BDropdownItem,
  BButton,
  BButtonGroup,
  BListGroupItem,
  BFormSelect,
  BFormInput,
  BTabs,
  BTab,
  BNavItem,
  BFormGroup,
  BForm,
  BProgress,
  BFormTextarea,
  BFormFile,
  BFormSelectOption,
  BFormCheckbox,
  BImg,
  BCollapse,
  VBToggle,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { db } from "@/main";
import { cloudinaryConf } from "@/main";
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import firebase from "firebase/compat/app";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { scriptString, widgetString } from "./code";
import { Twitter, Chrome } from "vue-color";

import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email, min, url, max } from "@validations";
import ru from "vee-validate/dist/locale/ru.json";
import { VueGoodTable } from "vue-good-table";
import { regex } from "vee-validate/dist/rules";

import store from "@/store/index";

localize("ru", ru);
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BDropdown,
    BDropdownItem,
    BButton,
    BButtonGroup,
    draggable,
    BListGroupItem,
    BFormSelect,
    BFormInput,
    BFormGroup,
    BForm,
    BTabs,
    BTab,
    BNavItem,
    BProgress,
    VueSlider,
    BFormTextarea,
    BFormFile,
    BFormSelectOption,
    BFormCheckbox,
    FeatherIcon,
    ToastificationContent,
    BImg,
    BCollapse,
    VBToggle,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    VueGoodTable,
    "twitter-picker": Twitter,
    "chrome-picker": Chrome,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      scriptString,
      currentTab: null,
      currentSettingsTab: null,
      createSourceName: "",
      videoData: null,
      video: null,
      uploadValue: 0,
      isPaused: false,
      isMuted: false,
      isVideoEnded: false,
      isVideoOverlay: false,

      isEditControl: false,
      actionsOptions: [
        { value: null, text: "Выберите тип кнопки" },
        { value: "nextFrame", text: "Переход к другой сцене" },
        { value: "url", text: "Переход по ссылке" },
        { value: "leadForm", text: "Открыть Лид-форму" },
        { value: "anchor", text: "Переход по якорю" },
      ],
      settingsOptions: ["video", "elements", "integrations", "frame", "qr"],
      leadFormsAnswers: {
        leadFormId: null,
        projectId: this.$route.params.id,
        userId: localStorage.getItem("userId"),
        timeAt: firebase.firestore.FieldValue.serverTimestamp(),
        answers: {},
      },
      controlForEdit: null,
      isForm: false,
      answersSentSuccess: false,

      results: null,
      errors: [],
      file: null,
      filesSelected: 0,
      cloudName: "dter7pjsd",
      preset: "rhsiinlg",
      tags: "browser-upload",
      progress: 0,
      showProgress: false,
      fileContents: null,
      formData: null,
      videoDuration: 100,
      sliderShow: false,
      fileToUpload: null,
      showUpload: false,

      isVideoSettings: true,
      isControlsSettings: false,
      isIntegrationsSettings: false,
      isInsertSettings: false,
      isQrSettings: false,
      showTrimmer: true,

      isCreateFromSource: false,

      createFormName: "",
      createFormTitle: "",
      createFormSuccess: "Спасибо, Ваша заявка принята",
      questionsList: [
        {
          id: this.randomKey(),
          type: "email",
          title: "E-mail",
          enabled: true,
        },
        {
          id: this.randomKey(),
          type: "phone",
          title: "Телефон",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "name",
          title: "Имя",
          enabled: true,
        },
        {
          id: this.randomKey(),
          type: "string",
          title: "Произвольный вопрос",
          enabled: false,
        },
      ],
      questionOptionsList: [
        { value: "email", text: "E-mail" },
        { value: "phone", text: "Телефон" },
        { value: "name", text: "Имя" },
        { value: "string", text: "Свой вопрос" },
      ],

      editSceneIcon: "EditIcon",
      editProjectIcon: "EditIcon",
      isEditSceneName: false,
      isEditProjectName: false,
      integrationColumns: [
        {
          label: "Статус",
          field: "isTurned",
        },
        {
          label: "Название",
          field: "name",
        },
        {
          label: "Система",
          field: "type",
        },
      ],

      socials: null,
      isSocials: false,
      isSocialsSettings: false,
      typeOptions: [
        { value: "buttons", text: "Кнопки" },
        { value: "socials", text: "Мессенджеры" },
      ],

      sendFormColor: "#7367f0",
    };
  },
  metaInfo() {
    return {
      title:
        "moovee - " + (this.getProjectData ? this.getProjectData.name : ""),
    };
  },
  computed: {
    ...mapGetters("projects", [
      "getProjectData",
      "getCurrentSource",
      "getControls",
      "getSourcesList",
      "getSocialsList",
    ]),
    ...mapGetters("lead-forms", ["getFormsList", "getCurrentForm"]),
    ...mapGetters("integrations", ["getAvailableIntegrations"]),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    currentVideo() {
      if (this.getCurrentSource && this.getCurrentSource.url) {
        if (this.getCurrentSource.url) this.sliderShow = true;
        const str = this.getCurrentSource.url.replace(
          new RegExp(".mov$"),
          ".mp4"
        );
        // return "https://res.cloudinary.com/demo/video/upload/c_fill,g_north,h_250,w_250/dog.mp4";
        return str;
      }
      return null;
    },
    currentVideoControls: {
      get() {
        const controlsClone = [...this.getControls];
        return controlsClone;
      },
      set(newValue) {
        if (newValue)
          this.setControls({ newValue, sourceId: this.getCurrentSource.id });
      },
    },
    frameOptions() {
      const options = [];
      this.getSourcesList.forEach((source) => {
        options.push({ value: source.id, text: source.title });
      });
      return options;
    },
    leadFormsOptions() {
      const options = [];
      this.getFormsList.forEach((form) => {
        options.push({ value: form.id, text: form.name });
      });
      return options;
    },
    sliderValue: {
      get() {
        if (this.getCurrentSource) {
          const startAt = this.getCurrentSource.startAt;
          const endAt = this.getCurrentSource.endAt;
          return [startAt, endAt];
        } else return [0, 3];
      },
      set(newVal) {
        const startAt = newVal[0];
        const endAt = newVal[1];
        this.updateVideoRange({
          startAt,
          endAt,
          url: this.getCurrentSource.url_origin,
          id: this.getCurrentSource.id,
        });
      },
    },
    widgetInputString() {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "vq/";
      return `<iframe id="video-quest" src="${baseUrl}${this.$route.params.id}" width="120" height="210" frameBorder="0"></iframe>`;
    },
    bodyInputString() {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "body/";
      return `<iframe id="vq-body" src="${baseUrl}${this.$route.params.id}" width="120" height="210" frameBorder="0"></iframe>`;
    },
    getQrLink() {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "direct/";
      return `https://api.qrserver.com/v1/create-qr-code/?size=280x280&data=${baseUrl}${this.$route.params.id}`;
    },
    getQrDownloadLink() {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "direct/";
      return `https://api.qrserver.com/v1/create-qr-code/?size=640x640&data=${baseUrl}${this.$route.params.id}`;
    },
    getDirectLink() {
      const baseUrl =
        (process.env.VUE_APP_DOMAIN ||
          "https://video-quest-dev.herokuapp.com/") + "direct/";
      return `\n${baseUrl}${this.$route.params.id}`;
    },
    checkFile() {
      if (this.file.duration > 60 || this.file.size >= 40000000) return false;
      else return true;
    },
    integrationsList() {
      const integrationsList = this.getAvailableIntegrations.data;
      integrationsList.forEach((integration) => {
        const key = Object.keys(this.getAvailableIntegrations.answer).find(
          (key) =>
            this.getAvailableIntegrations.answer[key].displayId ===
            integration.displayId
        );
        integration.isTurned = this.getProjectData.integrations.includes(key);
      });
      return integrationsList;
    },
    turnedSocials() {
      let count = 0;
      for (const [key, value] of Object.entries(this.getSocialsList)) {
        if (value === true) count++;
      }
      return count;
    },
  },
  methods: {
    ...mapActions("projects", [
      "requestProjectData",
      "requestNextSource",
      "setControls",
      "setSocials",
      "addControl",
      "setCurrentSource",
      "createSource",
      "updateVideoRange",
      "removeControl",
      "renameSource",
      "removeSource",
      "renameProject",
      "linkIntegrations",
      "setInteractionType",
    ]),
    ...mapActions("lead-forms", [
      "requestForms",
      "requestLeadForm",
      "saveAnswers",
      "addForms",
    ]),
    ...mapActions("integrations", [
      "requestAvailableIntegrations",
      "LinkIntegrationToProject",
    ]),
    isActiveCotrolEdit(inp) {
      if (this.controlForEdit && inp.id === this.controlForEdit.id) return true;
      else return false;
    },
    randomKey() {
      let r = (Math.random() + 1).toString(36).substring(2);
      return r;
    },
    resetFormModal() {
      this.controlForEdit.leadForm = null;
      this.questionsList = [
        {
          id: this.randomKey(),
          type: "email",
          title: "E-mail",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "phone",
          title: "Телефон",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "name",
          title: "Имя",
          enabled: false,
        },
        {
          id: this.randomKey(),
          type: "string",
          title: "Произвольный вопрос",
          enabled: false,
        },
      ];
      this.createFormName = "";
      this.createFormSucess = "Спасибо, Ваша заявка принята";
    },
    test() {
      this.$bvModal.show("modal-create");
    },
    createSourceControl(value) {
      if (value === "add") {
        this.isCreateFromSource = true;
        this.$bvModal.show("modal-create");
      }
    },
    createFormControl(value) {
      if (value === "add") {
        this.resetFormModal();
        this.$bvModal.show("modal-form-create");
      }
    },
    changeSource() {
      if (!this.isCreateFromSource) {
        this.sliderShow = false;
        this.showUpload = false;
        this.fileToUpload = null;
        this.setCurrentSource(this.getSourcesList[this.currentTab]);
      } else {
        this.sliderShow = false;
        this.showUpload = false;
        this.fileToUpload = null;
        this.createSourceName = "";
      }
    },
    addSource() {
      this.createSource({
        title: this.createSourceName,
        projectId: this.$route.params.id,
      }).then((ans) => {
        if (this.isCreateFromSource) {
          this.controlForEdit.nextFrame = ans;
          this.isCreateFromSource = false;
        } else {
          const id = this.getSourcesList.findIndex((source) => {
            return source.id === ans;
          });
          // console.log(id);
          // this.currentTab = id;
          // this.setCurrentSource(this.getSourcesList[id]);
        }
      });
      this.$bvModal.hide("modal-create");
    },
    clearSource() {
      this.createSourceName = "";
      if (this.isCreateFromSource) {
        this.controlForEdit.nextFrame = null;
      }
    },
    clearSettings() {
      this.isControlsSettings = false;
      this.isVideoSettings = false;
      this.isIntegrationsSettings = false;
      this.isQrSettings = false;
      this.isInsertSettings = false;
      this.isSocialsSettings = false;
    },
    changeSettings() {
      switch (this.currentSettingsTab) {
        case 0: {
          this.clearSettings();
          this.isVideoSettings = true;
          break;
        }
        case 1: {
          this.clearSettings();
          if (this.getCurrentSource.interactionType === "socials")
            this.isSocialsSettings = true;
          else this.isControlsSettings = true;
          break;
        }
        case 2: {
          this.clearSettings();
          this.requestAvailableIntegrations(this.getProjectData.userId);
          this.isIntegrationsSettings = true;
          break;
        }
        case 3: {
          this.clearSettings();
          this.isInsertSettings = true;
          break;
        }
        case 4: {
          this.clearSettings();
          this.isQrSettings = true;
          break;
        }
      }
    },

    getVideoData(file) {
      let video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        file.duration = duration;
        console.log(file.size, file.duration);
      };
      video.src = URL.createObjectURL(file);
    },
    previewVideo(event) {
      this.showUpload = true;
      this.file = event.target.files[0];
      this.getVideoData(this.file);
      this.filesSelected = event.target.files.length;
      console.log(this.file.duration, this.file);
    },

    prepareFormData() {
      this.formData = new FormData();
      this.formData.append("upload_preset", cloudinaryConf.preset);
      this.formData.append("file", this.fileContents);
      this.formData.append("folder", localStorage.getItem("userId"));
      this.formData.append("quality", 60);
    },
    onUpload() {
      this.showProgress = true;
      this.sliderShow = false;
      console.log("upload", this.file);
      console.log(this.getCurrentSource.id);
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function() {
          this.fileContents = reader.result;
          this.prepareFormData();
          let cloudinaryUploadURL = `https://api.cloudinary.com/v1_1/${cloudinaryConf.cloudName}/upload/`;
          let requestObj = {
            url: cloudinaryUploadURL,
            method: "POST",
            data: this.formData,
            onUploadProgress: function(progressEvent) {
              this.progress = Math.round(
                (progressEvent.loaded * 100.0) / progressEvent.total
              );
            }.bind(this),
          };
          //show progress bar at beginning of post
          axios(requestObj)
            .then((response) => {
              this.results = response.data;
              const lowQuality = this.results.secure_url.replace(
                "upload/",
                "upload/q_50,c_scale,h_640,w_360,so_0,eo_30/"
              );
              const normalQuality = this.results.secure_url.replace(
                "upload/",
                "upload/q_70,c_scale,h_854,w_480,so_0,eo_30/"
              );
              const duration = Math.round(this.file.duration * 10) / 10;
              db.collection("sources")
                .doc(this.getCurrentSource.id)
                .update({
                  name: this.file.name,
                  url_low: lowQuality,
                  url_origin: this.results.secure_url,
                  url: normalQuality,
                  startAt: 0,
                  endAt: duration > 30 ? 30 : duration,
                  duration: duration,
                })
                .then(() => {
                  const newSource = this.getCurrentSource;
                  newSource.name = this.file.name;
                  newSource.url_low = lowQuality;
                  newSource.url_origin = this.results.secure_url;
                  newSource.url = normalQuality;
                  newSource.startAt = 0;
                  newSource.endAt = 15;
                  newSource.duration = duration;
                  this.setCurrentSource(newSource);
                });
            })
            .catch((error) => {
              this.errors.push(error);
              console.log(error);
            })
            .finally(() => {
              setTimeout(
                function() {
                  this.progress = 0;
                  this.showProgress = false;
                  this.sliderShow = true;
                  this.showUpload = true;
                  this.requestProjectData(this.$route.params.id);
                }.bind(this),
                1000
              );
            });
        }.bind(this),
        false
      );

      if (this.file && this.file.name) {
        reader.readAsDataURL(this.file);
      }
    },
    playPauseVideo() {
      if (this.isPaused) {
        this.isVideoOverlay = false;
        this.$refs.videobackground.player.play();
      } else {
        this.isVideoOverlay = true;
        this.$refs.videobackground.player.pause();
      }
      this.isPaused = !this.isPaused;
    },

    setVoice() {
      this.isMuted = !this.isMuted;
    },
    addButton() {
      this.addControl({
        currentControls: this.getControls,
        sourceId: this.getCurrentSource.id,
      }).then((ans) => {
        this.controlForEdit = ans;
        this.isEditControl = true;
      });
    },
    changeType() {
      this.setInteractionType({
        type: this.getCurrentSource.interactionType,
        id: this.getCurrentSource.id,
      });
      this.clearSettings();
      this.currentSettingsTab = null;
    },
    handleAction(button) {
      if (button.action == "nextFrame" && button.nextFrame) {
        this.requestNextSource(button.nextFrame);
        const id = this.getSourcesList.findIndex(
          (source) => source.id === button.nextFrame
        );
        this.currentTab = id;
      }
      if (button.action == "url" && button.goTo) {
        window.open(button.goTo, "_blank").focus();
      }
      if (button.action == "leadForm" && button.leadForm) {
        this.$refs.videobackground.player.pause();
        this.isVideoOverlay = true;
        this.sendFormColor = button.background
          ? button.background.hex
          : "#7367f0";
        this.displayLeadForm(button);
      }
    },
    editControl(inp) {
      if (!inp.background) {
        inp.background = { hex: "#7367f0" };
      }
      this.controlForEdit = inp;
      this.isEditControl = true;
    },
    checkSocials() {
      const cont = this.getSocialsList;
      if (
        (cont.isWhatsapp && (!cont.whatsapp || cont.whatsapp.length === 0)) ||
        (cont.isTelegram && (!cont.telegram || cont.telegram.length === 0)) ||
        (cont.isViber && (!cont.viber || cont.viber.length === 0))
      )
        return false;
      else if (!cont.isWhatsapp && !cont.isTelegram && !cont.isViber)
        return false;
      else return true;
    },
    saveEditControl() {
      const newValue = this.getControls;
      this.$refs.controlsRules.validate().then((success) => {
        if (success) {
          this.setControls({ newValue, sourceId: this.getCurrentSource.id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  variant: "success",
                  title: "Кнопка сохранена",
                  icon: "CheckIcon",
                },
              });
            })
            .catch((e) => {
              console.log(e);
              this.$toast({
                component: ToastificationContent,
                props: {
                  variant: "danger",
                  title: "Что-то пошло не так",
                  icon: "XIcon",
                },
              });
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              variant: "danger",
              title: "Запоните обязательные поля",
              icon: "XIcon",
            },
          });
        }
      });
    },
    displayLeadForm(data) {
      this.requestLeadForm(data.leadForm);
      this.leadFormsAnswers.leadFormId = data.leadForm;
      this.isForm = true;
    },
    saveFormAnswers() {
      this.$refs.leadFormsRules.validate().then((success) => {
        if (success) {
          this.answersSentSuccess = true;
        }
      });
    },

    getSocialsValidation(type) {
      switch (type) {
        case "whatsapp": {
          return {
            regex: "^(https?:\/\/)?wa\.me\/(?:invite\/)?([a-zA-Z0-9_\/-]{1,})$",
          };
        }
        case "telegram": {
          return {
            regex: "^(https?:\/\/)?t\.me\/(?:invite\/)?([a-zA-Z0-9_\/-]{1,})$",
          };
        }
        case "viber": {
          return {
            regex:
              "^(https?:\/\/)?viber\.me\/(?:invite\/)?([a-zA-Z0-9_\/-]{1,})$",
          };
        }
        case "instagram": {
          return {
            regex:
              "^((https?:\/\/)?ig\.me\/(?:m\/)?([a-zA-Z0-9_\/-]{1,}))|((https?:\/\/)?instagram\.com\/?([a-zA-Z0-9_\/-]{1,}))$",
          };
        }
        case "vk": {
          return {
            regex: "^(https?:\/\/)?vk\.com\/?([a-zA-Z0-9_?=\/-]{1,})$",
          };
        }
      }
    },
    openSocial(link) {
      window.open(link, "_blank").focus();
    },
    saveSocials() {
      const newValue = this.getSocialsList;
      let socialsChecker = this.checkSocials();
      this.$refs.socialsRules.validate().then((success) => {
        if (success && socialsChecker) {
          this.setSocials({ newValue, sourceId: this.getCurrentSource.id })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  variant: "success",
                  title: "Мессенджеры сохранены",
                  icon: "CheckIcon",
                },
              });
            })
            .catch((e) => {
              console.log(e);
              this.$toast({
                component: ToastificationContent,
                props: {
                  variant: "danger",
                  title: "Что-то пошло не так",
                  icon: "XIcon",
                },
              });
            });
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              variant: "danger",
              title: "Запоните обязательные поля",
              icon: "XIcon",
            },
          });
        }
      });
    },
    deleteButton(button) {
      this.$swal({
        title: "Вы действительно хотите удалить кнопку?",
        text: "После удаления, её невозможно будет восстановить",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Отменить",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeControl({ button, source: this.getCurrentSource });
          this.controlForEdit = null;
          this.isEditControl = false;
        }
      });
    },
    closeForm() {
      this.isForm = false;
      this.isVideoOverlay = false;
      this.$refs.videobackground.player.play();
    },

    createLeadForm(formData) {
      this.addForms(formData).then((ans) => {
        this.controlForEdit.leadForm = ans.id;
      });
    },
    doCopy(string) {
      this.$copyText(string).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Текст скопирован",
            icon: "BellIcon",
          },
        });
      });
    },
    videoEnds() {
      this.isVideoOverlay = true;
      this.isVideoEnded = true;
    },
    videoPlaying() {
      this.isVideoEnded = false;
      this.isVideoOverlay = false;
    },
    videoRepeat() {
      this.isVideoOverlay = false;
      this.$refs.videobackground.player.play();
    },
    async downloadQr() {
      const image = await fetch(this.getQrDownloadLink);
      const imageBlog = await image.blob();
      const imageURL = URL.createObjectURL(imageBlog);
      const link = document.createElement("a");
      link.href = imageURL;
      link.download = "VideoQuest";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getValidationType(type) {
      switch (type) {
        case "email":
          return {
            required,
            email,
          };
        case "name":
          return {
            required,
            min: 2,
          };
        case "phone":
          return {
            regex: `([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$`,
            required,
          };
        case "string":
          return {
            required,
          };
      }
      return "";
    },

    editSceneName() {
      if (this.isEditSceneName) {
        this.renameSource(this.getCurrentSource);
        this.editSceneIcon = "EditIcon";
      } else {
        this.editSceneIcon = "SaveIcon";
      }
      this.isEditSceneName = !this.isEditSceneName;
    },

    editProjectName() {
      if (this.isEditProjectName) {
        this.renameProject({
          name: this.getProjectData.name,
          id: this.$route.params.id,
        });
        this.editProjectIcon = "EditIcon";
      } else {
        this.editProjectIcon = "SaveIcon";
      }
      this.isEditProjectName = !this.isEditProjectName;
    },
    deleteSource() {
      this.$swal({
        title: "Вы действительно хотите удалить сцену?",
        text: "После удаления, её невозможно будет восстановить",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Да, удалить!",
        cancelButtonText: "Отменить",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.removeSource({
            source: this.getCurrentSource,
            project: { ...this.getProjectData, id: this.$route.params.id },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                variant: "success",
                title: "Сцена удалена",
                icon: "CheckIcon",
              },
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          });
        }
      });
    },
    setIntegration(integration) {
      const key = Object.keys(this.getAvailableIntegrations.answer).find(
        (key) =>
          this.getAvailableIntegrations.answer[key].displayId ===
          integration.displayId
      );
      this.LinkIntegrationToProject({
        integration: key,
        project: this.$route.params.id,
        status: integration.isTurned,
      });
      this.linkIntegrations({
        integration: key,
        project: this.$route.params.id,
        status: integration.isTurned,
      });
    },
  },
  mounted() {
    this.requestProjectData(this.$route.params.id);
    this.requestForms();
    localize({
      ru,
    });
  },
  destroyed() {
    this.setCurrentSource(null);
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
.add-source-button {
  align-self: center;
  > .nav-link {
    padding: 0;
  }
}

.preview-wrapper {
  padding-right: 0 !important;
  > .card {
    box-shadow: none !important;
    border-radius: 0;
  }
  .preview-tabs-wrapper {
    padding-left: 0;
    padding-right: 0;
    .tabs {
      .tab-content {
        display: none;
      }
      .nav-vertical {
        width: 100%;
      }
    }
    .nav-pills {
      align-items: start;
      .nav-item {
        width: 100%;
        .nav-link {
          place-content: start;
        }
      }
    }
  }
}

.controls-wrapper {
  padding-left: 0 !important;
  > .card {
    box-shadow: none !important;
    background-color: #f2f2f2;
    border-radius: 0;
  }
}
.socials-wrapper {
  display: flex;
  align-items: center;
  place-content: center;
}

.video-body {
  .card-body {
    display: flex;
    .answer-success-overlay {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: -webkit-center;
      color: white;
      svg {
        color: #28c76f;
      }
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: center;
      }
    }
    .video-content-wrapper {
      width: 100%;
      border-radius: 15px;
      .vue-responsive-videobg {
        border-radius: 15px;
        .video-wrapper {
          border-radius: 15px;
          video {
            border-radius: 15px;
          }
        }
      }
      .voice-controller {
        color: white;
      }
      .video-custom-button {
        min-height: 100%;
        display: flex;
        .btn-lg {
          height: 52px;
          box-shadow: 0 0 10px rgb(225 255 255 / 50%);
        }
        .custom-button {
          border: transparent;
          border-radius: 8px;
          margin-top: 8px;
          height: auto;
        }
        .form-elements {
          margin-top: 8px;
        }
        .form-elements-button {
          button {
            border: transparent;
            border-radius: 8px;
          }
          margin-top: 8px;
        }
        > div {
          width: 90%;
          text-align: -webkit-center;
          text-align: -moz-center;
          > div {
            position: absolute;
            bottom: 60px;
            width: 90%;
          }
        }
      }
    }
  }
}

.list-group-item {
  transition: all 0.2s;
}

.form-control:disabled {
  background-color: #f8f8f8;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Выбрать";
}

.custom-file-input:lang(ru) ~ .custom-file-label::after {
  content: "Выбрать";
}
</style>
